
import { mapGetters, mapMutations, mapActions } from 'vuex'
import formValidation from "@/mixins/formValidation";
import offerFilters from "@/apollo/queries/offer/onlyOfferFiltersIndex";
import filters from "@/mixins/filters";

export default {
    mixins: [formValidation, filters],
    props: {
        hasChose: {
            type: Boolean,
            default: true
        },
        isModal: {
            type: Boolean,
            default: false
        },
        offer: Object
    },
    data() {
        return {
            readyYear: 'falseReadyYear',
            readyPrice: 'falseReadyPrice',
            readyPage: 'pause',
            selectedMark: {
                title: 'Марка',
                isOpen: false
            },
            selectedModel: {
                title: 'Модель',
                isOpen: false
            },
            selectedGeneration: {
                title: 'Поколение',
                isOpen: false
            },
            selectedYear: {
                title: 'Год от',
                isOpen: false
            },
            currentYear: null,
            isCredit: false,
            error: '',
            creditParams: {
                rangePeriodValues: [
                    "2",
                    "6",
                    "12",
                    "24",
                    "36",
                    "48",
                    "60",
                    "72",
                    "84",
					"96"
                ],
                rangePaymentValues: [
                    '0%',
                    '10%',
                    '20%',
                    '30%',
                    '40%',
                    '50%',
                    '60%',
                    '70%',
                    '80%',
                ],
                period: 96,
                payment: 0,
            },

        }
    },
    computed: {
        selectedMark_isOpen() {
            return this.selectedMark.isOpen
        },
        selectedGeneration_isOpen() {
            return this.selectedGeneration.isOpen
        },
        selectedModel_isOpen() {
            return this.selectedModel.isOpen
        },
        selectsYears() {
            if (this.filters?.year) {
                let years = [];
                for (let i = this.filters?.year[1]; i >= this.filters?.year[0]; i--) {
                    years.push(i);
                }
                return years;
            }
        },
        ...mapGetters({
            filters: 'filters/filters/filters',
            currentCar: 'modal/modal-choose/currentCar',
            activeModal: 'modal/modal-main/modal',
            allMarks: 'marks/marks/allMarks',
            models: 'modal/modal-choose/models',
            generations: 'modal/modal-choose/generations',
            currentMark: 'modal/modal-choose/currentMark',
            currentModel: 'modal/modal-choose/currentModel',
            currentGeneration: 'modal/modal-choose/currentGeneration',
            chosen: 'filters/filters/chosen',
        }),
        modelsArr() {
            return this.models && this.models.length ? this.models.sort((a, b) => a.title > b.title ? 1 : -1) : null
        },
        generationsArr() {
            return this.generations && this.generations.length ? this.generations.sort((a, b) => a.title > b.title ? 1 : -1) : null
        },
        marksLength() {
            return this.allMarks ? this.allMarks.reduce((prev, next) => prev += next.offers_count, 0) : ''
        },
    },
    methods: {
        ...mapActions({
            openModal: 'modal/modal-main/openModal',
            closeModal: 'modal/modal-main/closeModal',
            sendForm: 'form/form/sendForm',
            chooseMark: 'modal/modal-choose/chooseMark',
            chooseModel: 'modal/modal-choose/chooseModel',
            chooseGeneration: 'modal/modal-choose/chooseGeneration',
            setUrl: 'filters/filters/setUrl',
            request: 'filters/filters/request',
            clearData: 'modal/modal-choose/clearData'
        }),
        ...mapMutations({
            // setCurrentCar: 'form/form-credit/SET_CURRENT_CAR',
            setChosen: 'filters/filters/SET_CHOSEN',
            setCurrentCar: 'modal/modal-choose/SET_CURRENT_CAR',

            setGenerations: 'modal/modal-choose/SET_GENERATIONS',
            setModels: 'modal/modal-choose/SET_MODELS',
            SET_CURRENT_MARK: 'modal/modal-choose/SET_CURRENT_MARK',
            SET_CURRENT_MODEL: 'modal/modal-choose/SET_CURRENT_MODEL',
            SET_CURRENT_GENERATION: 'modal/modal-choose/SET_CURRENT_GENERATION',
            SET_CURRENT_CAR: 'modal/modal-choose/SET_CURRENT_CAR',
        }),

        ...mapMutations({
            setTabComponent: 'modal/modal-choose/SET_TAB_COMPONENT'
        }),
        fullReset() {
            this.clearData()
            this.setGenerations([])
            this.setModels([])

            this.SET_CURRENT_CAR(null)
            this.SET_CURRENT_GENERATION(null)
            this.SET_CURRENT_MODEL(null)
        },
        async check(data, type) {
            if (this.isCredit) {
                switch (type) {
                    case 'mark':
                        this.clearData()
                        this.setGenerations([])
                        this.setModels([])
                        this.currentYear = null;
                        this.SET_CURRENT_CAR(null)
                        this.SET_CURRENT_GENERATION(null)
                        this.SET_CURRENT_MODEL(null)
                        this.chooseMark(data)
                        break
                    case 'folder':
                        this.currentYear = null;
                        this.SET_CURRENT_CAR(null)
                        this.SET_CURRENT_GENERATION(null)
                        this.setGenerations([])
                        this.chooseModel(data)
                        break
                    case 'generation':
                        this.currentYear = null;
                        this.SET_CURRENT_CAR(null)
                        this.chooseGeneration(data)
                        break

                    default:
                        break;
                }
            }
            this.setChosen({ key: type, value: [data] })
            if (!this.isCredit) {
                this.readyPrice = 'falseReadyYear'
                this.readyYear = 'falseReadyPrice'
                if (type == 'mark') {
                    this.setChosen({ key: 'folder', value: null })
                    this.setChosen({ key: 'generation', value: null })
                    this.currentYear = null;
                }
                if (type == 'folder') {
                    this.setChosen({ key: 'generation', value: null })
                    this.currentYear = null;
                }
                if (type == 'generation') {
                    this.currentYear = null;
                }
                this.getParams(type)
            }
        },
        async getParams(type) {
            await this.filterRequest({
                mark_slug_array: this.chosen?.mark ? [this.chosen?.mark[0]?.slug] : null,
                folder_slug_array: this.chosen?.folder ? [this.chosen?.folder[0]?.slug] : null,
                generation_slug_array: this.chosen?.generation ? [this.chosen?.generation[0]?.slug] : null,
            }, type);
        },
        async filterRequest(assignVariables, type) {
            try {
                let response = await this.request({ query: offerFilters, variables: assignVariables })
                await this.changingFilters(response.data.offerFilters)

                if (type == 'mark') {
                    this.clearData()
                    this.setGenerations([])
                    this.SET_CURRENT_CAR(null)
                    this.SET_CURRENT_GENERATION(null)
                    this.SET_CURRENT_MODEL(null)
                    this.SET_CURRENT_MARK(response.data?.offerFilters?.chosen?.mark[0]);
                    this.setModels(response.data?.offerFilters?.folder);
                }
                if (type == 'folder') {
                    this.SET_CURRENT_CAR(null)
                    this.SET_CURRENT_GENERATION(null)
                    this.setGenerations([])

                    this.SET_CURRENT_MODEL(response.data?.offerFilters?.chosen?.folder[0]);
                    this.setGenerations(response.data?.offerFilters?.generation);
                }
                if (type == 'generation') {
                    this.SET_CURRENT_CAR(null)
                    this.chooseGeneration(response.data?.offerFilters?.chosen?.generation[0]);
                }
                // this.chooseMark(data)
                this.readyPrice = 'trueReadyYear'
                this.readyYear = 'trueReadyPrice'
            } catch (error) {
                return this.$nuxt.error({ statusCode: 404, message: '404' })
            }
        },
        async changingFilters(payload) {
            await this.$store.commit('filters/filters/SET_FILTERS', payload)
            await this.$store.commit('filters/filters/SET_ALL_CHOSEN', payload.chosen)
            setTimeout(() => {
                if (payload.price) {
                    this.setChosen({ key: 'priceFrom', value: payload.price[0] })
                    this.setChosen({ key: 'priceTo', value: payload.price[1] })
                }
                if (payload.year) {
                    this.setChosen({ key: 'yearFrom', value: payload.year[0] - 1 })
                    this.setChosen({ key: 'yearTo', value: payload.year[1] })
                }
            }, 1500)
        },
        checkYear(yearFrom, yearTo) {
            this.currentYear = yearFrom;
            this.setChosen({ key: 'yearFrom', value: yearFrom })
            this.setChosen({ key: 'yearTo', value: yearTo })
        },
        choseCar() {
            this.error = ''
            let payload = {
                modal_component: 'modal-choose',
                modal_title: 'Выберите автомобиль',
                modal_sub_title: `${this.marksLength} автомобилей в наличии`
            }
            this.openModal(payload)
        },
        tabClick(index) {
            this.setTabComponent(index)
            this.choseCar()
        },
        checkForm() {
            if (this.hasChose) {
                if (!this.currentCar) {
                    this.error = 'invalid_car'
                    setTimeout(function () { window.scrollTo(0, -100); }, 1);
                    return false
                }
            }
            if (this.form.car.value.length < 2) {
                this.form.car.valid = false
                return false
            }
            if (this.form.name.value.length < 2) {
                this.form.name.valid = false
                return false
            }
            if (this.form.date.value === '' || this.form.date.value.split('_').length > 1) {
                this.form.date.valid = false
                return false
            }
            let nowDate = Number(new Date().getFullYear()) - 18;
            if (Number(this.form.date.value.split('/')[0]) > 32 || Number(this.form.date.value.split('/')[1]) > 13
                || Number(this.form.date.value.split('/')[2]) > nowDate || Number(this.form.date.value.split('/')[2]) < 1900) {
                this.form.date.valid = false
                return false
            }
            if (!this.form.phone.valid) {
                this.form.phone.valid = false
                return false
            }
            if (!this.form.agree) {
                this.error = 'agree'
                return false
            }
            // if (!this.form.agreeRf) {
            // 	this.error = 'agreeRf'
            // 	return false
            // }
            return true;
        },
        async submitForm() {
            if (this.checkForm()) {
                let formData = {
                    chosen_car: this.currentCar || this.offer, //нужно для страницы thanks
                    external_id: this.hasChose ? this.currentCar.external_id : this.offer.external_id,
                    type: 'trade-in',
                    client_name: this.form.name.value,
                    client_phone: this.form.phone.value,
                    client_age: this.form.date.value,
                    client_vehicle_mark: this.form.car.value,
                }
                // utm
                if (localStorage.utm_source) {
                    formData.utm_source = localStorage.utm_source
                }
                if (localStorage.utm_medium) {
                    formData.utm_medium = localStorage.utm_medium
                }
                if (localStorage.utm_campaign) {
                    formData.utm_campaign = localStorage.utm_campaign
                }
                if (localStorage.utm_term) {
                    formData.utm_term = localStorage.utm_term
                }
                if (localStorage.utm_content) {
                    formData.utm_content = localStorage.utm_content
                }
                if (this.isCredit) {
                    formData.comment = 'В кредит'
                    formData.credit_initial_fee = this.form.paymentValue.toString()
                    formData.credit_period = this.form.periodValue.toString()
                }
                await this.sendForm(formData)
                await this.closeModal()
            }
        },
    },
    mounted() {
        this.fullReset()
        this.readyPage = 'start';

        if (window) {
            window.addEventListener('click', () => {
                this.selectedMark.isOpen = false
                this.selectedModel.isOpen = false
                this.selectedGeneration.isOpen = false
                this.selectedYear.isOpen = false
            })
        }


    },
    destroyed() {
        if (window) {
            window.removeEventListener('click', () => {
                this.selectedMark.isOpen = false
                this.selectedModel.isOpen = false
                this.selectedGeneration.isOpen = false
                this.selectedYear.isOpen = false
            })
        }
    },
    watch: {
        isCredit() {
            this.clearData()
            this.setGenerations([])
            this.setModels([])
            this.currentYear = null;
            this.SET_CURRENT_CAR(null)
            this.SET_CURRENT_GENERATION(null)
            this.SET_CURRENT_MODEL(null)
        },
        selectedMark_isOpen(value) {
            if (value) {
                this.selectedGeneration.isOpen = false
                this.selectedModel.isOpen = false
            }
        },
        selectedGeneration_isOpen(value) {
            if (value) {
                this.selectedMark.isOpen = false
                this.selectedModel.isOpen = false
            }
        },
        selectedModel_isOpen(value) {
            if (value) {
                this.selectedGeneration.isOpen = false
                this.selectedMark.isOpen = false
            }
        },

        currentMark(value) {
            // this.selectedMark = value.title
            // this.setModels([])
            // this.setGenerations([])
            // this.setCurrentCar(null)
            if (value === null) this.selectedMark.title = 'Марка'
        },
        currentModel(value) {
            // this.setGenerations([])
            // this.setCurrentCar(null)

            if (value === null) this.selectedModel.title = 'Модель'
        },
        currentGeneration(value) {
            // this.setCurrentCar(null)

            if (value === null) this.selectedGeneration.title = 'Поколение'
            this.isCredit && !this.activeModal && this.currentMark && this.currentModel && value !== null && this.choseCar()

        },
        generations(value) {
            if (value && value.length === 1) {
                this.selectedGeneration.title = value[0].name
            }
        }

    }

}
